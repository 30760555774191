import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PRODUCT_CREATE_RESET } from "../../Redux/Constants/ProductConstants";
import {createProduct, listProducts} from "../../Redux/Actions/ProductActions";
import Toast from "../LoadingError/Toast";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import {listCategorys} from "../../Redux/Actions/CategoryActions";
import Product from "./Product";
import {CATEGORY_CREATE_RESET} from "../../Redux/Constants/CategoryConstants";
import FileBase64 from "react-file-base64";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const AddProductMain = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");
  const [image9, setImage9] = useState("");
  const [image10, setImage10] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState("");
  const [categoryid, setCategoryid] = useState("");

  const dispatch = useDispatch();

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categorys } = categoryList;

  useEffect(() => {
    if (product) {
      toast.success("Product Added", ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      setName("");
      setDescription("");
      setCategoryid("");
      setCountInStock(0);
      setImage("");
      setImage2("");
      setImage3("");
      setImage4("");
      setImage5("");
      setImage6("");
      setImage7("");
      setImage8("");
      setImage9("");
      setImage10("");
      setPrice(0);
    }
  }, [product, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProduct(name, price, description,categoryid, image, image2, image3, image4,image5,image6,image7,image8,image9,image10, countInStock));
  };

  useEffect(() => {
    dispatch(listCategorys());
  }, [ dispatch]);

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title">Add product</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Product title
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="product_title"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="product_price"
                      required
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Count In Stock
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="product_price"
                      required
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      rows="7"
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Category</label>
                    <select required onChange={(e) => setCategoryid(e.target.value)} className="form-select" aria-label="Default select example">
                      <option selected>--Choose a category--</option>
                      {categorys?.map((category) => (
                          <option value={category._id}>{category.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image principale</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage(base64, image) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 2</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage2(base64, image2) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 3</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage3(base64, image3) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 4</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage4(base64, image4) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 5</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage5(base64, image5) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 6</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage6(base64, image6) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 7</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage7(base64, image7) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 8</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage8(base64, image8) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 9</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage9(base64, image9) }/>
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Image 10</label>
                    <FileBase64 required multiple={ false } onDone={ ({ base64 }) => setImage10(base64, image10) }/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
